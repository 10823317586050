import { SVGProps, Ref, forwardRef } from 'react'

const SvgImageFolder = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.902 17.662V5.047A1.577 1.577 0 0 1 3.48 3.47h15.77a1.577 1.577 0 0 1 1.576 1.577v1.577"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.826 6.623H6.634c-.871 0-1.577.706-1.577 1.577v11.038c0 .871.706 1.577 1.577 1.577h14.192c.87 0 1.577-.706 1.577-1.577V8.2c0-.87-.706-1.577-1.577-1.577Z"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M9.787 11.748a.394.394 0 1 1 0-.788M9.787 11.748a.394.394 0 0 0 0-.788"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.248 17.662-3.06-4.588a.789.789 0 0 0-1.303-.016l-2.102 2.997-1.3-1.04a.79.79 0 0 0-1.149.179l-1.639 2.468"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgImageFolder)
export default ForwardRef
